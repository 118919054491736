import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CreateSaveBodyService } from 'src/app/workflow-editor/services/create-save-body.service';
import { GITHUB_API } from 'src/app/workflow-editor/consts/api';
import { of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UDPDatasetOperatorService {

    private baseApiUrl = environment.baseApiUrl;

    constructor(
        private http: HttpClient,
        public createSaveBodyService: CreateSaveBodyService,
    ) {
    }

    getDataAssestCategories(dataAssetTypes, customerId: string, userId: string) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${this.baseApiUrl}visualizations/customer/${customerId}/user/${userId}/assets/${dataAssetTypes}/data-asset-categories`);
    }
    getAssetLists(dataAssetCategoryID: string, type: string) {
        // return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
        return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
    }

    saveConfig(data: any, fileName: string) {
        const json = JSON.stringify(data);
        const body = this.createSaveBodyService.createSaveBody(json, 'json', fileName);
        return this.http.post(GITHUB_API, body);
    }

    getConfig(fileName: string, dagFileSha) {
        const { token, nodeId } = this.createSaveBodyService.parameters;
        return this.http.get(`${GITHUB_API}?token=${token}&repository=${nodeId}&filename=${fileName}&sha=${dagFileSha}`);
    }

    getSchema(datasetId: string) {
        return this.http.get(`${this.baseApiUrl}dataasset/schemaMetadata?datasetID=${datasetId}`);
    }
    getDataSourceType(id: any) {
        const REF_DATA_SOURCE_CATEGORY = [
            {
                id: '1',
                code: 'RDB',
                name: 'Relational Database'
            },
            {
                id: '2',
                code: 'EAPP',
                name: 'Enterprise Application/SaaS Platform'
            },
            {
                id: '3',
                code: 'CS',
                name: 'Cloud Storage'
            },
            {
                id: '4',
                code: 'NSQL',
                name: 'NoSQL'
            },
            {
                id: '5',
                code: 'GDB',
                name: 'Graph Database'
            },
            {
                id: '6',
                code: 'DW',
                name: 'Data Warehouse'
            },
            {
                id: '7',
                code: 'DL',
                name: 'Data Lake'
            },
            {
                id: '8',
                code: 'DF',
                name: 'Data File'
            },
        ];
        const source: any = REF_DATA_SOURCE_CATEGORY.find((src: any) => src.id === id);
        return source ? source.code : '';
    }
    getDataAsset(assetID: string, type: string) {
        return this.http.get(`${environment.dmgPyBaseUrl}/dataasset/asset?type=${type}&assetID=${assetID}`)
    }
}
