import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { GITHUB_API } from 'src/app/workflow-editor/consts/api';

import { CreateSaveBodyService } from 'src/app/workflow-editor/services/create-save-body.service';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UPDMilOperatorService {

    private currentEntityGroupId = '';
    private currentEntityGroupName = '';

    private baseApiUrl = environment.baseApiUrl;
    private mdmSha = environment.mdmSha;

    targetFilePath: any = new BehaviorSubject<any>(null);
    public targetFilePath$ = this.targetFilePath.asObservable();

    selectedFields: any = new BehaviorSubject<any>(null);
    public selectedFields$ = this.selectedFields.asObservable();

    entityDataMappingUploadMode: any = new BehaviorSubject<any>(null);
    public entityDataMappingUploadMode$ = this.entityDataMappingUploadMode.asObservable();

    constructor(
        private http: HttpClient,
        public createSaveBodyService: CreateSaveBodyService,
    ) {
    }

    public getCurrentEntityGroupId() {
        return this.currentEntityGroupId;
    }

    public getCurrentEntityGroupName() {
        return this.currentEntityGroupName;
    }

    getDataAssestCategories(dataAssetTypes, customerId: string, userId: string) {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${this.baseApiUrl}visualizations/customer/${customerId}/user/${userId}/assets/${dataAssetTypes}/data-asset-categories`);
    }

    getAssetLists(dataAssetCategoryID: string, type: string) {
        // return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${environment.dataAssetURL}allassets?dataAssetCategoryID=${dataAssetCategoryID}&type=${type}`);
    }

    getMdmData() {
        // tslint:disable-next-line: max-line-length
        return this.http.get(`${this.baseApiUrl}github/files?token=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&repository=MDM-Config&filename=Mdm.config.json&sha=${this.mdmSha}`).toPromise();
    }

    saveConfig(data: any, fileName: string) {
        const json = JSON.stringify(data);
        const body = this.createSaveBodyService.createSaveBody(json, 'json', fileName);
        return this.http.post(GITHUB_API, body);
    }

    getConfig(fileName: string) {
        const { token, nodeId, dagFileSha } = this.createSaveBodyService.parameters;
        // return this.http.get(`${GITHUB_API}?token=${token}&repository=${nodeId}&filename=${fileName}&sha=${dagFileSha}`);
        return of({
            datasetDataSource: {
                dataAsset: {
                    dataFileAsset: {
                        dataFileSelection: {
                            dataFileNamePattern: '$YYYY$$MM$DD$'
                        },
                        includeFiles: 'CUSTOM_DATE_RANGE',
                        customDateRange: {
                            dateFrom: '2022-07-13T18:30:00.000Z',
                            dateTo: ''
                        },
                        includeSubFolders: true,
                        subFolderPathPattern: 'test'
                    }
                }
            },
            targetData: {},
            customerId: 'DEMOUSER',
            userId: 'demouser'
        });
    }

    getSchema(datasetId: string, dataSource: string) {
        // return this.http.get(`${this.baseApiUrl}dataasset/schemaMetadata?datasetID=${datasetId}`);
        return this.http.get(`${this.baseApiUrl}metadata/datasource/${datasetId}/${dataSource}/entitygroups`);
    }

    /**
     * @description To get the entity group first and then get all entities attached to that entity group
     * Combined two API calls in a single Observable call
     */
    getEntityGroupList(datasetId: string, dataSource: string) {
        return this.http.get(
            `${this.baseApiUrl}metadata/datasource/${datasetId}/${dataSource}/entitygrouplist`).pipe(
                map((entityGroup) => {
                    this.currentEntityGroupId = entityGroup && entityGroup[0].entityGroupID;
                    this.currentEntityGroupName = entityGroup && entityGroup[0].entityGroupName;
                    return entityGroup && entityGroup[0].entityGroupID;
                }),
                mergeMap(entityGroupId =>
                    this.http.get(`${this.baseApiUrl}metadata/entityGroup/${entityGroupId}/current/entities`))
                , take(1)
            );
    }

    getEntity(entityId): Observable<any> {
        return this.http.get(`${this.baseApiUrl}metadata/entityGroup/${this.currentEntityGroupId}/entities/${entityId}`);
    }

    getDataSourceType(id: any, name?: boolean) {
        const REF_DATA_SOURCE_CATEGORY = [
            {
                id: '1',
                code: 'RDB',
                name: 'Relational Database'
            },
            {
                id: '2',
                code: 'EAPP',
                name: 'Enterprise Application/SaaS Platform'
            },
            {
                id: '3',
                code: 'CS',
                name: 'Cloud Storage'
            },
            {
                id: '4',
                code: 'NSQL',
                name: 'NoSQL'
            },
            {
                id: '5',
                code: 'GDB',
                name: 'Graph Database'
            },
            {
                id: '6',
                code: 'DW',
                name: 'Data Warehouse'
            },
            {
                id: '7',
                code: 'DL',
                name: 'Data Lake'
            },
            {
                id: '8',
                code: 'DF',
                name: 'Data File'
            },
        ];
        const source: any = REF_DATA_SOURCE_CATEGORY.find((src: any) => src.id === id);
        if (name) { // If parameter is passed as true, we will return name of dataSource type
            return source ? source.name : '';
        } else {
            return source ? source.code : '';
        }
    }

    getDataAsset(assetID: string, type: string) {
        return this.http.get(`${environment.dmgPyBaseUrl}/dataasset/asset?type=${type}&assetID=${assetID}`);
    }

    getBucketExplorer(formData, s3Value) {
        return this.http.post(`${this.baseApiUrl}${s3Value}`, formData);
    }

}
