/* tslint:disable */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
import EnvConfig from './environments.config';

/**
 * @constant
 */
// const baseUrl: string = 'https://api.dev.xfusiontech.io';

// const baseApi: string = 'https://54.218.128.93:8280';

// const baseUrl: string = 'https://xaqua-udp-wso2-am-dev.xaquaudp.io:8280';

/**
 * @constant
 */
// Use when WSO2 is not available
const dmgbaseUrl = 'https://xaqua-udp-core-dmg-api-dev.xaquaudp.io';
const dmgPyBaseUrl = 'https://xaqua-udp-core-dmg-api-py-dev.xaquaudp.io';
const pypipelinebaseUrl = 'https://xaqua-data-pipeline-api-py-dev.xaquaudp.io';
const workflowEngineBaseUrl = 'https://xaqua-workflow-engine-api-dev.xaquaudp.io';
const workflowEngineUiBaseUrl = 'https://xaqua-workflow-engine-ui-dev.xaquaudp.io';
const pipelinebaseUrl = 'https://xaqua-data-pipeline-api-dev.xaquaudp.io';
const coresecbaseUrl = 'https://xaqua-udp-core-sec-api-dev.xaquaudp.io';
const periscopevisualuibaseUrl = 'https://xaqua-udp-periscope-visual-ui-dev.xaquaudp.io';
const periscopevisualbaseUrl = 'https://xaqua-udp-periscope-visual-api-dev.xaquaudp.io';



// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  ...new EnvConfig(),
  //Stage URL:
  Person360GraphQLURL: 'https://sp360udp.xaquaudp.io/graphql',
  updateMetadataUrl: `${dmgbaseUrl}/metadata/files/`,
  fileImportUrl: `${dmgbaseUrl}/metadata/users/ssantra/dataAssetCategories`,
  dataAssetURL: `${dmgPyBaseUrl}/dataasset/`,
  dataPipelinePythonAPIURL: `${pypipelinebaseUrl}/github/`,
  workflowEditorUIURL:``,
  operatorsUrl: `${dmgbaseUrl}/uploadoperators/`,

  // baseApiUrl: 'https://xaqua-udp-wso2-am-dev.xaquaudp.io:8280',
  baseApiUrl: `${dmgbaseUrl}/`,
  dmgPyBaseUrl:dmgPyBaseUrl,
  mdmSha: 'd2430d46cb71ec22557ebc1d455818756b7abec9',

  // wso2URL: 'https://devops-xaqua-udp-wso2-am.xfusiontech.io:9443',
  dataPipelinePythonBaseURL: `${pypipelinebaseUrl}/`,
  workflowBaseAPI: `${workflowEngineBaseUrl}/`,
  dataAssetPermissionURL: `${dmgbaseUrl}/dataasset/`,
  dataAssetCategory: `${dmgbaseUrl}/dataAssetCategory/`,
  visualizations: `${dmgbaseUrl}/visualizations/`,
  refBaseURL: `${dmgbaseUrl}/refdataset/`,
  workspaceUIURL: `${pipelinebaseUrl}/udpcore/`,
  tagMetadataURL: `${dmgbaseUrl}/metadata/`,
  userDataSession: `${dmgPyBaseUrl}/userDataSession`,
  queryAsset: `${dmgbaseUrl}/dataAsset/`,
  customerURL: `${coresecbaseUrl}/udp-core/`,
  userManagementUrl: `${coresecbaseUrl}/udp-core/usermgt`,
  adminURL: `${coresecbaseUrl}/`,

  editorConfig: {
    theme: 'vs-dark',
    language: 'plaintext',
    automaticLayout: true,
    scrollBeyondLastLine: false,
    contextmenu: false
  },

  // data-studio env

  existingDataAssetsWorkflows: `${dmgPyBaseUrl}/dataAssetWorkflow/dataAssetWorkflows`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
/* tslint:enable */
